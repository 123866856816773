import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { injectIntl, intlShape } from 'gatsby-plugin-intl'

import messages from './messages'

import './styles.scss'

function DesignerCard({ designer, intl }) {
  const { firstName, lastName, profilePicture } = designer
  const name = `${firstName} ${lastName}`

  return (
    <div className="designer-card">
      <Link
        to={`/designers/${firstName.toLowerCase()}-${lastName.toLowerCase()}`}
      >
        <figure>
          {profilePicture && (
            <img
              src={profilePicture.file.url /*  + '?fit=thumb&h=200&q=100' */}
              alt={intl.formatMessage(messages.imageCaption, { name })}
            />
          )}

          <figcaption>{name}</figcaption>
        </figure>
      </Link>
    </div>
  )
}

DesignerCard.propTypes = {
  designer: PropTypes.shape({
    contentful_id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
}

export default injectIntl(DesignerCard)

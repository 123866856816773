import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import DesignerCard from '../components/DesignerCard'

function DesignersPage() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulDesigner {
        edges {
          node {
            contentful_id
            firstName
            lastName
            profilePicture {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)
  const designers = data.allContentfulDesigner.edges.map(({ node }) => node)

  return (
    <Layout>
      <SEO title="Designers" />

      <div className="designers-content">
        {designers.map(designer => (
          <DesignerCard key={designer.contentful_id} designer={designer} />
        ))}
      </div>
    </Layout>
  )
}

export default DesignersPage

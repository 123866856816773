import { defineMessages } from 'gatsby-plugin-intl'

const key = 'components.designer-card'

const messages = defineMessages({
  imageCaption: {
    id: `${key}.imageCaption`,
    defaultMessage: `Photo of {name}`,
  },
})

export default messages
